<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-card-code>
          <b-row>
            <b-col cols="12" md="6">
              <label>Module</label>
              <v-select
                v-model="selectedModule"
                placeholder="Select Module"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="w-100"
                :reduce="val => val.id"
                :clearable="false"
                :options="moduleOptions"
              />
            </b-col>
            <b-col cols="12" md="6">
              <label>Languages</label>
              <v-select
                v-model="remarkForm.selectedLanguages"
                class="language"
                :disabled="true"
                placeholder="Select Languages"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="remarkForm.selectedLanguages"
                :reduce="val => val.value"
                :clearable="false"
              />
            </b-col>

            <b-col v-if="selectedModule === 2" cols="12" md="6">
              <label>Airlines</label>
              <v-select
                v-model="selectedAirline"
                placeholder="Select Airlines"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="airlines"
                :reduce="val => val.value"
                :clearable="false"
              />
            </b-col>
          </b-row>
        </b-card-code>

        <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
        <b-card-code v-for="(item, index) in remarkForm.selectedLanguages" v-if="selectedModule === 1 || selectedModule === 3 || (selectedModule === 2 && selectedAirline)" :key="index">
          <b-row>
            <b-col cols="12" md="12">
              <validation-provider
                #default="{ errors }"
                :name="`${item.label}`"
                rules="required"
              >
                <b-form-group
                  :label="`${item.label} Description`"
                  label-for="mc-description"
                >

                  <template>
                    <quill-editor
                      v-model="remarkForm.remarkLanguage[index].description"
                      :options="snowOption"
                    />
                  </template>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card-code>
        <Loader v-if="contentLoading" />
        <!-- submit button -->
        <b-col md="12" class="demo-inline-spacing">
          <b-button
            :disabled="loading"
            variant="primary"
            type="submit"
            @click="editRemark"
          >
            Submit
            <div v-if="loading" class="spinner-border spinner-border-sm" />
          </b-button>
          <b-button
            variant="secondary"
            :to="{name: 'dashboard'}"
          >
            Cancel
          </b-button>

        </b-col>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BForm, BFormGroup, BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import savedTripsService from '@/services/saved-trips/savedTrips.service'
import errorResponseHandler from '@/services/errorHandler'
import { resConditionCheck } from '@/helpers/constant'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import languageService from '@/services/language/language.service'
import { required } from '@/@core/utils/validations/validations'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import remarksService from '@/services/remarks/remarks.service'
import flightService from '@/services/flight/flight.service'
import Loader from '@/layouts/skeloton-loader/Loader.vue'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    vSelect,
    quillEditor,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BButton,
    Loader
  },

  data() {
    return {
      contentLoading: true,
      moduleOptions: [],
      remarkForm: {
        selectedLanguages: [],
        remarkLanguage: [],
      },
      staticObject: {
        description: '',
        language_id: '',
        module_id: '',
        airline_id: '',
        id: ''
      },
      snowOption: {},
      loading: false,
      required,
      selectedModule: '',
      selectedAirline: '',
      items: [],
      airlines: [],
      remarkId: '',
      moduleId: ''
    }
  },

  watch: {
    selectedAirline: {
      handler(newValue) {
        this.selectedAirline = newValue
        const mainObject = this.items.find(e => (e.id === this.moduleId))
        this.remarkId = mainObject.id
        for (let i = 0; i < this.remarkForm.remarkLanguage.length; i += 1) {
          this.remarkForm.remarkLanguage[i].airline_id = newValue

          this.remarkForm.remarkLanguage[i].description = mainObject.remarkLanguages.find(z => (z.airlineId === newValue && z.languageId === this.remarkForm.remarkLanguage[i].language_id))?.description

          this.remarkForm.remarkLanguage[i].id = mainObject.remarkLanguages.find(z => (z.airlineId === newValue && z.languageId === this.remarkForm.remarkLanguage[i].language_id))?.id
          this.remarkForm.remarkLanguage[i].module_id = this.moduleId
        }
      }
    },

    selectedModule: {
      handler(newValue) {
        if (newValue === 2) {
          this.selectedAirline = ""
        }
        this.moduleId = newValue
        const mainObject = this.items.find(e => (e.id === this.moduleId))
        this.remarkId = mainObject.id
        if (newValue === 1 || newValue === 3) {
          for (let i = 0; i < this.remarkForm.remarkLanguage.length; i += 1) {
            this.remarkForm.remarkLanguage[i].description = mainObject.remarkLanguages.find(z => (z.languageId === this.remarkForm.remarkLanguage[i].language_id)).description

            this.remarkForm.remarkLanguage[i].id = mainObject.remarkLanguages.find(z => (z.languageId === this.remarkForm.remarkLanguage[i].language_id))?.id

            this.remarkForm.remarkLanguage[i].module_id = newValue
          }
        }
      }
    }
  },

  async mounted() {
    await this.getAirlineListing()
    await this.getRemarksListing()
    await this.getModuleType()
    await this.getLanguageDropdown()
    for (let i = 0; i < this.remarkForm.remarkLanguage.length; i += 1) {
      this.remarkForm.remarkLanguage[i].description = this.items.find(e => (e.moduleId === 1)).remarkLanguages.find(z => (z.languageId === this.remarkForm.remarkLanguage[i].language_id)).description
      this.remarkForm.remarkLanguage[i].module_id = 1

      const mainObject = this.items.find(e => (e.id === 1))
      this.remarkForm.remarkLanguage[i].id = mainObject.remarkLanguages.find(z => (z.languageId === this.remarkForm.remarkLanguage[i].language_id))?.id
    }
  },

  methods: {
    async getModuleType() {
      try {
        const res = await savedTripsService.getTripType()
        if (resConditionCheck(res.status) && res.data.data) {
          this.moduleOptions = res.data.data.map(e => ({
            ...e,
            label: e.name
          }))
          this.selectedModule = this.moduleOptions.find(e => (e.id === 1)).id
        }
      } catch (error) {
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    async getAirlineListing() {
      try {
        const res = await flightService.getAirlineListing()
        if (resConditionCheck(res.status) && res.data.data) {
          this.airlines = res.data.data.map(e => ({
            label: e.name,
            value: e.id
          }))
        }
      } catch (error) {
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    async getLanguageDropdown() {
      try {
        const res = await languageService.getLanguageDropdown()
        if (resConditionCheck(res.status) && res.data.data) {
          this.remarkForm.selectedLanguages = res.data.data.map(e => ({
            label: e.name,
            value: e.id
          }))
          for (let i = 0; i < res.data.data.length; i += 1) {
            this.remarkForm.remarkLanguage.push({ ...this.staticObject, language_id: res.data.data[i].id })
          }
        }
      } catch (error) {
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    async getRemarksListing() {
      this.contentLoading = true
      try {
        const res = await remarksService.getRemarksListing()
        if (resConditionCheck(res.status) && res.data.data) {
          this.contentLoading = false
          this.items = res.data.data
        }
      } catch (error) {
        this.contentLoading = false
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    async editRemark() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          remarksService.editRemark(this.remarkId, { remarkLanguage: this.remarkForm.remarkLanguage }).then(async(res) => {
            if (resConditionCheck(res.status) && res.data.data) {
              this.loading = false
              if(this.remarkId === 2) {
                let remarkIdSelected = this.remarkId, airlineIdSelected = this.selectedAirline, moduleIdSelected = this.selectedModule;
                await this.getRemarksListing();
                this.remarkId = remarkIdSelected;
                this.selectedAirline = airlineIdSelected;
                this.selectedModule = moduleIdSelected;
                
                const moduleRemarks = this.items.find(it=> it.moduleId == this.selectedModule)
                let getRemarkLang = moduleRemarks.remarkLanguages.filter(item=> item.remarkId == this.remarkId && item.airlineId == this.selectedAirline);
                for (let i = 0; i < this.remarkForm.remarkLanguage.length; i += 1) {
                  const remid = getRemarkLang.find(z => (z.languageId === this.remarkForm.remarkLanguage[i].language_id))
                  this.remarkForm.remarkLanguage[i].id = remid.id ? remid.id : '';
                }
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
            }
          }).catch(error => {
            this.loading = false
            const errorData = errorResponseHandler(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.ql-editor {
    min-height: 150px;
    max-height: 350px
}
</style>
