import axios from 'axios'

function getFlightListing(payload) {
  return axios.get('flight/list', { params: payload })
}

function switchAirportFraudFlag(id) {
  return axios.put(`flight/${id}`)
}

function getAirlineListing() {
  return axios.get('airline')
}

export default {
  getFlightListing,
  switchAirportFraudFlag,
  getAirlineListing
}
