import axios from 'axios'

function getRemarksListing() {
  return axios.get('remark')
}

function editRemark(id, data) {
  return axios.put(`remark/${id}`, data)
}

export default {
  getRemarksListing,
  editRemark
}
